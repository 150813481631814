var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:{
    'input-group': _vm.hasIcon,
    'has-danger': _vm.error,
    'input-group-focus': _vm.focused,
    'has-label': _vm.label || _vm.$slots.label,
    'has-placeholder': _vm.placeholder,
    'has-success': _vm.hasSuccess,
    'is-focused': _vm.focused,
    'is-filled': _vm.isFilled$,
    'is-disabled': _vm.disabled,
    'form-group_search': _vm.search,
    'form-group_bordered': _vm.bordered,
    'form-group_input-icon': _vm.inputIcon,
  }},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"form-group__label",class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(_vm.labelInfo)?_c('el-tooltip',{attrs:{"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.labelInfo)}})]),_c('i',{staticClass:"nc-icon nc-alert-circle-i label-info"})]):_vm._e()],1):_vm._e()]}),_c('div',{staticClass:"form-group__body",class:{
      'form-group__body_label-overlay': _vm.labelOverlay,
    }},[(_vm.addonLeftIcon || _vm.$slots.addonLeft)?_c('div',{staticClass:"input-group-prepend",attrs:{"disabled":_vm.disabled}},[_c('span',{staticClass:"input-group-text",on:{"click":function($event){return _vm.$emit('click-left-icon')}}},[_vm._t("addonLeft",function(){return [_c('i',{class:_vm.addonLeftIcon})]})],2)]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"position-relative w-100"},[_c('input',{staticClass:"form-control"}),_c('ui-loading')],1):[(_vm.search)?_c('ui-icon',{staticClass:"form-group__search-icon",attrs:{"name":"search","size":"16"}}):_vm._e(),(_vm.inputIcon)?_c('ui-icon',{staticClass:"form-group__custom-icon",attrs:{"name":_vm.inputIcon,"size":"20"}}):_vm._e(),_vm._t("default",function(){return [(_vm.tag === 'input' || _vm.tag === 'textarea')?_c(_vm.tag,_vm._g(_vm._b({key:"input-value-native",ref:"input",tag:"component",attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"keypress":_vm.numberHandler,"paste":_vm.numberHandler}},'component',_vm.controlScope,false),_vm.listeners)):_c(_vm.tag,_vm._g(_vm._b({key:"input-value-prop",ref:"input",tag:"component",attrs:{"placeholder":_vm.placeholder,"value":_vm.value,"disabled":_vm.disabled}},'component',_vm.controlScope,false),_vm.listeners)),(_vm.extPostfix)?_c('span',{staticClass:"d-flex text-grey font-12 align-self-end ml-2"},[_vm._t("extPostfix")],2):_vm._e()]}),(_vm.addonRightIcon || _vm.$slots.addonRight)?_c('div',{staticClass:"input-group-append",attrs:{"disabled":_vm.disabled}},[_c('span',{staticClass:"input-group-text",on:{"click":function($event){return _vm.$emit('click-right-icon')}}},[_vm._t("addonRight",function(){return [_c('i',{class:_vm.addonRightIcon})]})],2)]):_vm._e()]],2),_vm._t("infoBlock"),_vm._t("helpBlock",function(){return [(_vm.showErrorMessage)?_c('div',{staticClass:"invalid-feedback error-text",class:{'mt-2': _vm.hasIcon}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }