<template>
  <ui-tooltip
    :placement="placement"
    :content="copyText"
    :value="visible"
    manual
  >
    <slot v-bind="{ copyTextInfo }">
      <ui-icon-button
        name="copy-text"
        size="16px"
        class="d-inline-flex"
        @click.prevent="copyTextInfo"
      />
    </slot>
  </ui-tooltip>
</template>

<script>

export default {

  props: {
    text: [String, Number],
    placement: {
      type: String,
      default: 'top',
    },
  },

  data() {
    return {
      visible: false,
      copyText: 'Copied!',
    };
  },

  methods: {
    copyTextInfo() {
      try {
        navigator.clipboard.writeText(this.text);
      } catch (e) {
        console.error(e);
        this.text = 'No copied!';
      } finally {
        this.visible = true;
        setTimeout(() => {
          this.visible = false;
        }, 1000);
      }
    },
  },
};
</script>
